.clientsLogos{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: black;
    padding: 30px 100px 50px 100px;
    margin: 45px 0px;
  }

  .titulo{
    text-align: center;
    margin-bottom: 25px;
    color: white;
  }
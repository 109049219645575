.nav-item {
    color: #008996;
    text-decoration: none;
    cursor: pointer;
}

.nav-item:hover{
    color: #008996;
}

.logoHeading{
    height: 100px;
}

#logoNavbar {
     height: 35px;
}

.downArrow {
    color: #F93549;
}
#navbarNavDropdown{
    justify-content: center;
}

.dropdown-item{
    color: #008996;
    text-decoration: none;
}
.sticky-top{
    background-color: white;
}